<template>
  <v-container>
    <h1 v-html="$t('search_segmentation_relations_title')"></h1>
    <v-row class="row search spacer-md">
      <v-col xs="12" sm="6" md="3">
        <search-dairy-segmentation ref="search-dairy-segmentation" @search="search" />
      </v-col>
      <v-col xs="12" sm="6" md="3">
        <search-segmentation-relation ref="search-segmentation-relation" @search="search" />
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" class="mb-3">
        <v-btn color="primary" @click="search"><span v-html="$t('search_segmentation_relations_search')" /></v-btn>
        <v-btn color="secondary" @click="reset"><span v-html="$t('search_segmentation_relations_reset')" /></v-btn>
        <v-btn color="secondary" @click="createNewRelation"><span v-html="$t('search_segmentation_relations_new_relationship')" /></v-btn>
        <v-btn color="secondary" @click="copyRelationDialog"><span v-html="$t('search_segmentation_relations_copy_relationships')" /></v-btn>
      </v-col>
    </v-row>
    <v-data-table-server
      show-select
      :headers="fields"
      :items="items"
      class="zero-width top-margin"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page-options="$itemsPerPageOptions"
      :items-per-page="query.size"
      :multi-sort="true"
      density="compact"
      ><template v-slot:headers="dairyOutIdent">
        <tr>
          <th colspan="3">
            <div class="disappear">{{ dairyOutIdent }}</div>
          </th>
          <th colspan="3" class="grey-background" v-html="$t('search_segmentation_relation_ident_out')"></th>
          <th colspan="3" class="grey-background" v-html="$t('search_segmentation_relation_ident_in')"></th>
        </tr>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <template v-if="item.id">
          <router-link
            :to="{
              name: 'segmentationRelations_edit',
              params: { id: item.id }
            }"
            class="tableaction link"
          >
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          </router-link>
        </template>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:[`item.dairyOutIdent`]="{ item }">
        <template v-if="item.dairyOutIdent">
          <div v-if="item.legalEntityOut">
            <div v-if="partnerIsActive(item.dairyOutValidUntil)">
              {{ item.dairyOutIdent }}
            </div>
            <div v-if="!partnerIsActive(item.dairyOutValidUntil)" class="red-writing">
              {{ item.dairyOutIdent }}
            </div>
          </div>
          <div v-else>{{ item.dairyOutIdent }}</div>
        </template>
      </template>
      <template v-slot:[`item.legalEntityOut.name1`]="{ item }">
        <template v-if="item.legalEntityOut && item.legalEntityOut.name1">
          <div v-if="partnerIsActive(item.dairyOutValidUntil)">
            {{ item.legalEntityOut.name1 }}
          </div>
          <div v-if="!partnerIsActive(item.dairyOutValidUntil)" class="red-writing">
            {{ item.legalEntityOut.name1 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.legalEntityOut.name2`]="{ item }">
        <template v-if="item.legalEntityOut && item.legalEntityOut.name2">
          <div v-if="partnerIsActive(item.dairyOutValidUntil)">
            {{ item.legalEntityOut.name2 }}
          </div>
          <div v-if="!partnerIsActive(item.dairyOutValidUntil)" class="red-writing">
            {{ item.legalEntityOut.name2 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.dairyInIdent`]="{ item }">
        <template v-if="item.dairyInIdent">
          <div v-if="item.legalEntityIn">
            <div v-if="partnerIsActive(item.dairyInValidUntil)">
              {{ item.dairyInIdent }}
            </div>
            <div v-if="!partnerIsActive(item.dairyInValidUntil)" class="red-writing">
              {{ item.dairyInIdent }}
            </div>
          </div>
          <div v-else>{{ item.dairyInIdent }}</div>
        </template>
      </template>
      <template v-slot:[`item.legalEntityIn.name1`]="{ item }">
        <template v-if="item.legalEntityIn && item.legalEntityIn.name1">
          <div v-if="partnerIsActive(item.dairyInValidUntil)">
            {{ item.legalEntityIn.name1 }}
          </div>
          <div v-if="!partnerIsActive(item.dairyInValidUntil)" class="red-writing">
            {{ item.legalEntityIn.name1 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.legalEntityIn.name2`]="{ item }">
        <template v-if="item.legalEntityIn && item.legalEntityIn.name2">
          <div v-if="partnerIsActive(item.dairyInValidUntil)">
            {{ item.legalEntityIn.name2 }}
          </div>
          <div v-if="!partnerIsActive(item.dairyInValidUntil)" class="red-writing">
            {{ item.legalEntityIn.name2 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <template v-if="item.validFrom">
          {{ $formatChDate(item.validFrom) }}
        </template>
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        <template v-if="item.validUntil">
          {{ $formatChDate(item.validUntil) }}
        </template>
      </template>
    </v-data-table-server>
    <v-dialog persistent v-model="dialog" max-width="800px">
      <v-card>
        <v-form @submit.prevent="copyRelation()" ref="copyRelationForm">
          <v-card-title>
            <span v-html="$t('search_segmentation_relations_copy_title')" />
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- MBH-Ident -->
              <h2 v-html="$t('search_segmentation_relations_copy_mbh_ident')"></h2>
              <v-row>
                <v-col cols="3" sm="6" md="3">
                  <v-text-field
                    id="dairyOutIdent"
                    :loading="loadingOut"
                    :rules="[rules.required]"
                    v-model="dairyIdentFrom"
                    type="number"
                    :label="$t('search_segmentation_relations_copy_mbh_ident')"
                    @blur="loadDairyFrom(dairyIdentFrom)"
                  />
                </v-col>
                <v-col cols="3" sm="6" md="3">{{ errorMessageOut }} {{ dairyIdentFromName }} </v-col>
              </v-row>

              <!-- Stichtag -->
              <h2 v-html="$t('search_segmentation_relations_copy_reference_date')"></h2>
              <v-row>
                <v-col cols="3" sm="6" md="3">
                  <DbmDatePicker v-model="relationDate" :dateLabel="$t('search_segmentation_relations_copy_reference_date')" :required="true" />
                </v-col>
              </v-row>

              <!-- MBH-Ident  -->
              <h2 v-html="$t('search_segmentation_relations_copy_mbh_ident')"></h2>
              <v-row>
                <v-col cols="3" sm="6" md="3">
                  <v-text-field
                    id="dairyInIdent"
                    :loading="loadingIn"
                    :rules="[rules.required]"
                    v-model="dairyIdentTo"
                    type="number"
                    :label="$t('search_segmentation_relations_copy_mbh_ident')"
                    @blur="loadDairyTo(dairyIdentTo)"
                  />
                </v-col>
                <v-col cols="3" sm="6" md="3"> {{ errorMessageIn }} {{ dairyIdentToName }} </v-col>
              </v-row>

              <!-- per -->
              <h2 v-html="$t('search_segmentation_relations_copy_by')"></h2>
              <v-row>
                <v-col cols="3" sm="6" md="3">
                  <DbmDatePicker v-model="validFrom" :required="true" :dateLabel="$t('search_segmentation_relations_copy_by')" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit">
              <span v-html="$t('copy')" />
            </v-btn>
            <v-btn color="secondary" @click="cancelCopyRelation()">
              <span v-html="$t('close')" />
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '../../main'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import _ from 'lodash'
import searchDairySegmentation from '@/components/searchCards/searchDairySegmentation.vue'
import searchSegmentationRelation from '@/components/searchCards/searchSegmentationRelation.vue'
import DbmDatePicker from '../../../src/components/dbmDatePicker.vue'
import { DTSHeader } from '@/services/BackendService'
import { useSearchSegmentationRelationStore } from '@/store/SearchSegmentationRelationStore'
import { useSearchDairyStore } from '@/store/SearchDairyStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationRelations_read',
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      },
      fields: <DTSHeader[]>[
        // edit
        {
          title: this.$t('search_segmentation_relation_edit'),
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // Pos-Nr.
        {
          title: this.$t('search_segmentation_relation_id'),
          key: 'id',
          sortable: false,
          align: 'left'
        },
        // MBH-ID
        {
          title: this.$t('search_segmentation_relations_mbh_id'),
          key: 'dairyOutIdent',
          sortable: false
        },
        // Name / Firma
        {
          title: this.$t('search_segmentation_relations_name_company'),
          key: 'legalEntityOut.name1',
          sortable: false
        },
        // Vorname / Zusatz
        {
          title: this.$t('search_segmentation_relations_family_name_addition'),
          key: 'legalEntityOut.name2',
          sortable: false
        },
        // MBH-ID
        {
          title: this.$t('search_segmentation_relations_mbh_id'),
          key: 'dairyInIdent',
          sortable: false
        },
        // Name / Firma
        {
          title: this.$t('search_segmentation_relations_name_company'),
          key: 'legalEntityIn.name1',
          sortable: false
        },
        // Vorname / Zusatz
        {
          title: this.$t('search_segmentation_relations_family_name_addition'),
          key: 'legalEntityIn.name2',
          sortable: false
        },
        // Fütterung
        {
          title: this.$t('search_segmentation_relations_feeding'),
          key: 'feeding' + this.$getUpLangKey(),
          sortable: false
        },
        // Beziehung von
        {
          title: this.$t('search_segmentation_relations_relation_from'),
          key: 'validFrom',
          sortable: false
        },
        // bis
        {
          title: this.$t('search_segmentation_relations_to'),
          key: 'validUntil',
          sortable: false
        }
      ],
      dialog: false,
      dairyIdentFrom: useSearchDairyStore().Dairy_ident,
      dairyIdentFromName: this.loadDairyFrom(useSearchDairyStore().Dairy_ident),
      relationDate: useSearchSegmentationRelationStore().SegmentationRelation_relationDate,
      dairyIdentTo: undefined,
      dairyIdentToName: '',
      validFrom: undefined,
      loadingIn: false,
      loadingOut: false,
      errorMessageIn: '',
      errorMessageOut: ''
    }
  },
  computed: {
    term(): any {
      return [...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)]
    },
    params() {
      return {
        relationDate: useSearchSegmentationRelationStore().SegmentationRelation_relationDate,
        status: useSearchSegmentationRelationStore().SegmentationRelation_status,
        dairyIdentOut: useSearchSegmentationRelationStore().SegmentationRelation_identOut,
        dairyIdentIn: useSearchSegmentationRelationStore().SegmentationRelation_identin,
        feeding: useSearchSegmentationRelationStore().SegmentationRelation_feeding,
        id: useSearchSegmentationRelationStore().SegmentationRelation_id
      }
    },
    query() {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    searchDairySegmentation,
    searchSegmentationRelation,
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/segmentationRelations/find', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-dairy-segmentation'].reset()
      this.$refs['search-segmentation-relation'].reset()
    },
    createNewRelation() {
      this.$router.push({ name: 'segmentationRelations_create' }).catch(() => {
        /* duplicated route */
      })
    },
    copyRelationDialog() {
      this.dialog = true
    },
    cancelCopyRelation() {
      this.dialog = false
    },
    async copyRelation() {
      try {
        var bodyFormData = new FormData()
        bodyFormData.append('dairyIdentFrom', this.dairyIdentFrom)
        bodyFormData.append('relationDate', this.relationDate)
        bodyFormData.append('dairyIdentTo', this.dairyIdentTo)
        bodyFormData.append('validFrom', this.validFrom)
        const result = await this.axios.post(apiURL + '/segmentationRelations/copy', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        if (await result) {
          this.load()
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.dialog = false
        }
      } catch (e) {
        showError(e)
      }
    },
    back() {
      this.$router.go(-1)
    },
    async loadDairyTo(ident) {
      if (ident == null || ident == undefined || ident == '') return
      try {
        this.loadingIn = true
        const response = await this.axios.get(
          apiURL + '/dairies/findByIdent',
          {
            params: { ident: ident }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await response) {
          if (response.data) {
            this.dairyIdentTo = response.data.ident
            this.dairyIdentToName = response.data.name
            this.errorMessageIn = ''
          } else {
            this.dairyIdentToName = ''
            this.errorMessageIn = this.$t('segmentation_relations_no_such_dairy')
          }
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingIn = false
      }
    },
    async loadDairyFrom(ident) {
      if (ident == null || ident == undefined || ident == '') return

      try {
        this.loadingOut = true
        const response = await this.axios.get(
          apiURL + '/dairies/findByIdent',
          {
            params: { ident: ident }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await response) {
          if (response.data) {
            this.dairyIdentFrom = response.data.ident
            this.dairyIdentFromName = response.data.name
            this.errorMessageOut = ''
          } else {
            this.dairyIdentFromName = ''
            this.errorMessageOut = this.$t('segmentation_relations_no_such_dairy')
          }
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingOut = false
      }
    },
    partnerIsActive(validUntil) {
      if (validUntil == null || validUntil == undefined) {
        return true
      }
      let today = new Date()
      let expireDate = new Date(validUntil)
      return today <= expireDate
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.size) this.load()
    if (this.$route.query.relationDate) {
      useSearchSegmentationRelationStore().SegmentationRelation_relationDate = this.$route.query.relationDate as string
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.disappear {
  display: none;
}
.grey-background {
  background-color: $dbm-light-grey;
  border: 1px solid $dbm-passiv-grey !important;
}
.top-margin {
  margin-top: $spacer-xxxs;
}
</style>
